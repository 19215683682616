<template>
  <!-- 背包 -->
  <div class="backpack">
    <img
      class="backpackimg"
      src="@/assets/images/user/backpack/house.png"
      alt
    />
    <div class="header">
      <div class="footer-right">
        <div class="bd" @click="gunSplit(gunarr)">使用</div>
      </div>
      <!-- <div class="quanxuan" @click="all">
        <div>全选</div>
        <el-checkbox v-model="checked"></el-checkbox>
      </div> -->
    </div>
    <div class="main">
      <div
        class="gunlist"
        v-for="(item, index) in bagList"
        :key="index"
        :class="item.isshow ? 'gunboxs' : 'gunbox'"
        @click="gunclick(item.id, item.isshow)"
      >
        <div>
          <div class="gh">
            <img src="@/assets/images/user/peopleCenter/gold.png" alt />
            <div>{{ item.oprice }}</div>
          </div>
          <img :src="item.oimage" alt class="gm" />
          <div class="gf">{{ item.oname }}</div>
        </div>
        <div class="tip" v-if="item.isshow">&radic;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getuserhouse, updateUserData } from "../../../api/index";
import { Splithouse } from "../../../api/index";
export default {
  inject: ["reload"],
  data() {
    return {
      bagList: [],
      gunclass: false,
      gunid: [],
      gunarr: [],
      itemId: "",
      num: 1,
      checked: false,
    };
  },
  mounted() {
    getuserhouse().then((res) => {
      console.log(res);
      for (const item of res.data.data) {
        let aa = {};
        aa.id = item.firstChargeBonusId;
        aa.oimage = item.img;
        aa.exchangeTime = item.latestUseTime;
        aa.oname = item.name;
        aa.oprice = item.price;
        aa.status = item.useStatus;
        aa.isshow = false;
        this.bagList.push(aa);
      }
      // console.log(this.bagList);
    });
  },
  methods: {
    gunclick(id, show) {
      // console.log(id);
      //先判断是否已存在
      if (this.gunarr.includes(id)) {
        //如果有，删除
        this.gunarr = this.gunarr.filter(function (item) {
          return item !== id;
        });
        show = !show;
      } else {
        //如果没有，添加
        this.gunarr.push(id);
        show = !show;
      }
      for (let index = 0; index < this.bagList.length; index++) {
        if (this.bagList[index].id == id) {
          // console.log(id);
          this.bagList[index].isshow = show;
          // console.log(this.bagList[index].isshow);
        }
      }
      this.gunid = this.gunarr;
      this.itemId = id;
    },

    gunSplit(id) {
      if (id != "") {
        Splithouse(id).then((res) => {
          console.log(res);
          this.message(res.data.code);
          this.gunarr = [];
          updateUserData().then((res) => {
            res;
            this.reload();
          });
        });
      } else {
        this.$message.error("请选择优惠券");
      }
    },
    message(res) {
      if (res == 200) {
        this.$message.success("使用成功");
        getuserhouse().then((res) => {
          this.bagList = res.data.data;
        });
      } else {
        this.$message.error("操作失败或者已使用过");
      }
    },
    // all() {
    //   for (let index = 0; index < this.bagList.length; index++) {
    //     console.log(this.bagList[index].id);
    //     if (this.bagList[index].isshow == false) {
    //       this.checked = true;
    //       this.bagList[index].isshow = true;
    //       this.gunarr.push(this.bagList[index].id);
    //     } else if (this.bagList[index].isshow == true) {
    //       this.checked = false;
    //       this.bagList[index].isshow = false;
    //       this.gunarr = [];
    //     }
    //   }
    //   console.log(this.gunarr);
    // },
  },
};
</script>

<style scoped lang="scss">
.gunlist {
  position: relative;
}
.tip {
  width: 25px;
  height: 25px;
  border: 2px solid yellow;
  border-radius: 50%;
  color: yellow;
  text-align: center;
  line-height: 25px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.backpack {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/user/backpack/bg2.png");
  background-size: 100% 100%;

  .header {
    display: flex;
    color: #fff;
    .footer-right {
      display: flex;
      .bd {
        width: 80px;
        height: 20px;
        text-align: center;
        flex: none;
        margin-top: 6px;
        font-size: 14px;
        color: #fff;
        padding: 10px;
        background: url("~@/assets/images/logo/loginbtn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .quanxuan {
      display: flex;
      align-items: center;
      margin-right: 20px;
      position: relative;
      div {
        margin-right: 6px;
        width: 50px;
        position: absolute;
        z-index: 10;
      }
      /deep/.el-checkbox {
        position: absolute;
        z-index: 9;
        left: 35px;
      }
    }

    .yixuan {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }

  .main {
    width: 520px;
    height: 180px;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-evenly;
    overflow-y: scroll;

    .gunbox {
      width: 90px;
      height: 100px;
      background-image: url("~@/assets/images/user/backpack/bg1.png");
      background-size: 100% 100%;

      .gh {
        height: 10px;
        padding-top: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
          font-size: 10px;
          color: rgba(254, 227, 3, 1);
        }

        img {
          flex: none;
          margin-right: 4px;
          width: 10px;
          height: 10px;
        }
      }

      .gm {
        display: block;
        height: 50px;
        width: 90%;
        margin: auto;
      }

      .gf {
        width: 80%;
        margin: auto;
        font-size: 10px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .gunboxs {
      width: 90px;
      height: 100px;
      background-image: url("~@/assets/images/user/backpack/bg.png");
      background-size: 100% 100%;

      .gh {
        height: 10px;
        padding-top: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
          font-size: 10px;
          color: rgba(254, 227, 3, 1);
        }

        img {
          flex: none;
          margin-right: 4px;
          width: 10px;
          height: 10px;
        }
      }

      .gm {
        display: block;
        height: 50px;
        width: 90%;
        margin: auto;
      }

      .gf {
        width: 80%;
        margin: auto;
        font-size: 10px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .footer {
    width: 490px;
    margin: 16px auto;
    display: flex;
    justify-content: space-between;

    .footer-left {
      display: none;
      align-items: center;
      color: #fff;
      font-size: 14px;

      .fli {
        flex: none;
        width: 14px;
        height: 20px;
      }
    }
  }
}
@media (max-width: 550px) {
  .backpack {
    width: 100%;
    height: 100%;
    background: none;
    .backpackimg {
      display: none;
    }
    .header {
      display: flex;
      color: #fff;
      justify-content: space-around;

      .quanxuan {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        div {
          margin-right: 6px;
          width: 50px;
          position: absolute;
          z-index: 10;
        }
        /deep/.el-checkbox {
          position: absolute;
          z-index: 9;
          left: 35px;
        }
      }

      .yixuan {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }

    .main {
      width: 98%;
      height: 316px;
      margin: 10px auto;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-evenly;
      overflow: scroll;

      .gunbox {
        width: 50%;
        height: 130px;
        background-image: url("../../../assets/images/user/backpack/bg1.png");
        background-size: 100% 100%;

        .gh {
          height: 10px;
          padding-top: 16px;
          padding-right: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          div {
            font-size: 15px;
            color: rgba(254, 227, 3, 1);
          }

          img {
            flex: none;
            margin-right: 4px;
            width: 15px;
            height: 15px;
          }
        }

        .gm {
          display: block;
          height: 66px;
          width: 60%;
          margin: auto;
        }

        .gf {
          width: 80%;
          margin: auto;
          font-size: 14px;
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .gunboxs {
        width: 50%;
        height: 130px;
        background-image: url("~@/assets/images/user/backpack/bg.png");
        background-size: 100% 100%;

        .gh {
          height: 10px;
          padding-top: 16px;
          padding-right: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          div {
            font-size: 15px;
            color: rgba(254, 227, 3, 1);
          }

          img {
            flex: none;
            margin-right: 4px;
            width: 15px;
            height: 15px;
          }
        }

        .gm {
          display: block;
          height: 66px;
          width: 60%;
          margin: auto;
        }

        .gf {
          width: 80%;
          margin: auto;
          font-size: 14px;
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .footer {
      width: 50%;
      margin: 16px auto;
      display: flex;
      justify-content: space-between;

      .footer-left {
        display: none;
        align-items: center;
        color: #fff;
        font-size: 14px;

        .fli {
          flex: none;
          width: 14px;
          height: 20px;
        }
      }

      .footer-right {
        display: flex;

        img {
          display: block;
          width: 100px;
          height: 50px;
        }
      }
    }
  }
}
</style>
